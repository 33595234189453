areaFields = ->
  $("form .ccc-shift-form-container select#ccc_shift_ccc_area_id")

gradeFields = ->
  $("form").find("select#ccc_shift_ccc_vacancies_attributes_0_grade_tokens")

shiftGradeField = ->
  $("form .ccc-shift-form-container select#ccc_shift_grade_id")

selectedShiftGrade = ->
  shiftGradeField().find("option:selected")

setVacancyGradeFieldOptionsByGrade = ->
  return if shiftGradeField().length == 0

  return gradeFields().find("option").removeClass("hidden") if selectedShiftGrade().data("isTraining")

  gradeFields().find("option").addClass("hidden")
  if id = selectedShiftGrade().data("deptId")
    gradeFields().find("option[data-dept-id='#{id}']").removeClass("hidden")
  else
    gradeFields().select2("val", "")
    areaFields().select2("val", "")

setAreaOptions = ->
  # areas to be based on selected grade if grade is selectable
  areaFields().find("option").addClass("hidden") if shiftGradeField().length > 0

  if id = selectedShiftGrade().val()
    areaIds = selectedShiftGrade().data("ccc-area-ids")
    for areaId in "#{areaIds}".split(",")
      areaFields().find("option[value='#{areaId}']").removeClass("hidden")

loadGrades = (val)->
  return unless val # no value
  $container = $("form .ccc-shift-form-container")
  id = $container.find("select#ccc_shift_grade_id").select2("val")
  return unless id
  $.ajax
    url: Routes.client_grades_path($("#ccc_shift_client_id").val())
    data: {"id": id}
    dataType: "script"

updateUnitHours = ->
  diff = moment.utc(moment($("#ccc_shift_end_time").val(), 'HH:mm').diff(moment($("#ccc_shift_start_time").val(), 'HH:mm'))).format("HH:mm");
  hrsMins = diff.split(/[.:]/)
  hrs = parseInt(hrsMins[0], 10)
  mins = if hrsMins[1] then parseInt(hrsMins[1], 10) else 0
  $("#ccc_shift_unit_hours").val((hrs + mins / 60).toFixed(2))

selected_ccc_shift_ids = ->
  $selected = $(".ccc-shifts-container .ccc-shift .toggle:checked")
  $selected.map(-> $(@).val()).get().join(",")

$(document).on "page:show remote:load", ->
  # set on load for render purposes
  setVacancyGradeFieldOptionsByGrade()
  setAreaOptions()

  $("form .ccc-shift-form-container").each ->
    $container = $(@)

    $container.find("select#ccc_shift_grade_id").on "change", ->
      areaFields().select2("val", "")
      setAreaOptions()
      setVacancyGradeFieldOptionsByGrade()
      loadGrades $(@).val()

    $container.find(".radio_buttons.ccc_shift_shift_type input").on "change", ->
      $container.find("#tiered_publication").toggleClass("hidden", $(@).val() != "short")

    $container.find("#ccc_shift_start_time, #ccc_shift_end_time").on "change", ->
      updateUnitHours();

  $(".js-show-ccc-vacancy-comments").on "click", ->
    $(@).hide()
    id = $(@).data("candidate-id")
    $("#assign_wtd_ccc_candidate_#{id}").show()
    $(".js-assign-wtd-ccc-candidate-form[data-candidate-id='#{id}']").removeClass("hidden")

  $(".js-assign-wtd-ccc-candidate-form").on "click", ->
    id = $(@).data("candidate-id")
    $("form#assign_wtd_ccc_candidate_#{id}").submit()

  $(".ccc-shifts-container").on "change", ".toggle", ->
    isChecked = $(@).is(":checked")
    $toggleAll = $(".ccc-shifts-container input#ccc-shifts-toggle")
    $toggles = $(".ccc-shifts-container .ccc-shift .toggle")

    if $(@).is($toggleAll)
      $toggles.prop("checked", isChecked) # select all or none
    else
      # Have the all/none toggle be reflective of the toggles checked.
      $toggleAll.prop("checked", !$toggles.is(":not(:checked)"))

    # Allow actions to be performed if any shift is selected.
    if $toggles.is(":checked") then $.rails.showBulkEdit() else $.rails.hideBulkEdit($(@))

  $("#bulk-edit-footer").on "click", ".js-cancel", ->
    $.rails.hideBulkEdit $(".ccc-shifts-container")

  $("form#new-ccc-shift-batch-form").on "click", "input[type=submit]", (ev) ->
    method = $(@).data("method")
    $(ev.delegateTarget).each ->
      $(@).find("input[name=method]").val(method)
      $(@).find("input[name=ids]").val selected_ccc_shift_ids()

  $(".ccc_shifts .js-table-toggle-actions .actions").each ->
    $container = $(@)
    $container.load $container.data("path")
