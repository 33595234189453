getPossibleShifts = ->
  date = $("#timesheet_start_date").val()
  clientId = $("#timesheet_client_id").val()
  candidateId = $("#timesheet_candidate_id").val()
  timesheetId = $("input#timesheet_id").val()
  if date and clientId and candidateId
    $.ajax
      url: Routes.candidate_vacancies_path(candidateId)
      data: {"date": date, "client_id": clientId, "timesheet_id": timesheetId}
      dataType: "script"

selected_timesheet_ids = ->
  $selected = $(".timesheets.table td.js-selectable input:checked")
  $selected.map(-> $(@).val()).get().join(",")

$(document).on "page:show remote:load", ->
  getPossibleShifts() # on load for render purposes

  $("#timesheet_start_date, #timesheet_candidate_id").on "change", ->
    getPossibleShifts()

  $(".timesheets").each ->
    $(@).on "click", ".js-submit-form", ->
      $(".timesheets.table").each -> $("#timesheet_ids").val selected_timesheet_ids()
      $("#status").val $(@).data("status")
      $(@).closest("form").submit()
      $.rails.hideBulkEdit $(".timesheets.table")

    $(@).on "change", ".toggle", ->
      isChecked = $(@).is(":checked")
      $toggleAll = $("input#timesheets-toggle")
      $toggles = $("td.js-selectable .toggle")

      if $(@).is($toggleAll)
        $toggles.prop("checked", isChecked) # select all or none
      else
        # Have the all/none toggle be reflective of the toggles checked.
        $toggleAll.prop("checked", !$toggles.is(":not(:checked)"))

      # Allow actions to be performed if any timesheet is selected.
      if $toggles.is(":checked") then $.rails.showBulkEdit() else $.rails.hideBulkEdit($(@))

    $(@).on "mouseenter", "table tr", ->
      shiftId = $(@).data("shift-id")
      # find and highlight all shift timesheets
      $("tr[data-shift-id='#{shiftId}']").addClass("hover") if !!shiftId

    $(@).on "mouseleave", "table tr", ->
      $(".timesheets tr").removeClass("hover")

$(document).on "shown.bs.modal", ->
  $("#modal-adjustment").on "click", ".js-null-timesheet-hours", ->
    $(".warning").toggleClass("hidden")
    $(".starts_at, .ends_at").toggle()
