selectCalendarCell = (cell) ->
  return unless $(".js-edit-preferences").closest("li").hasClass("active")

  cell.toggleClass("selected", !cell.hasClass("selected"))

set_mark_for_destroy = (element) ->
  group = element.closest(".candidate_credential")
  selected = element.find(':selected')
  group.find("input[name*='_destroy']").each ->
    $(@).prop("value", not selected.val())

$(document).on "page:show remote:load", ->
  $("#candidate-calendars").trigger("calendars:load")

  $(".candidate-preferences").on "click", ".js-submit-form", ->
    selectedDates = $(".candidate-calendar .selected").map(-> $(@).data("date")).get()
    $form = $(@).closest("form")
    $form.find("input.dates").val selectedDates
    $form.find("input.preference").val $(@).data("preference")
    $form.submit()

  $(".candidate_candidate_shift_settings_preference input:checkbox").on "change", ->
    $(@).closest(".candidate_candidate_shift_settings_preference").find("input[name*='[_destroy]']").val !$(@).is(":checked")

  # setting checkboxes (client view)
  $(".candidate-setting-form table").on "change", ".toggle", ->
    isChecked = $(@).is(":checked")
    $toggleAll = $(".candidate-setting-form table th .toggle")
    $toggles = $(".candidate-setting-form table td .toggle")

    if $(@).is($toggleAll)
      $toggles.prop("checked", isChecked) # select all or none
    else
      # Have the all/none toggle be reflective of the toggles checked.
      $toggleAll.prop("checked", !$toggles.is(":not(:checked)"))

  $(".candidate-shifts-container:empty").each ->
    $.rails.throb on, $(@)
    $(@).load $(@).data("path")

  $("select#candidate_ffp3_fit_test").change ->
    $field = $("input[name*='ffp3_fit_test_valid_from_date']").val("")
    $field.closest(".row").toggleClass("hide", !$(@).val())

  $(".candidate_credential .credential_type").on "change", ->
    set_mark_for_destroy $(@)

  # Go directly to a given tab, specified by a page target in the url
  tabName = if window.location.hash
    window.location.hash
  $(".candidates-nav a[href='#{tabName}']").tab("show") if tabName

$(document).on "calendars:load", ->
  $(".zabuto_calendar").remove()

  $(".candidate-calendar").each ->
    candidate_id = $("#candidate-calendars").data("candidate-id")

    $(@).zabuto_calendar
      language: "en"
      show_previous: false
      show_next: false
      year: $(@).data("year")
      month: $(@).data("month")
      action: -> selectCalendarCell $(@)
      ajax:
        url: Routes.calendar_candidate_preferences_path(candidate_id)

    # load calendar data
    $.ajax
      url: Routes.calendar_candidate_preferences_path(candidate_id)
      dataType: "script"
      data:
        year: $(@).data("year")
        month: $(@).data("month")
