$(document).on "page:show", ->
  $(".panel .associated-rate-cards-container").each ->
    $container = $(@)
    $responseContainer = $container.find(".load-results")
    $throbber = $container.find(".fa-spin-container")
    $.ajax
      url: $container.data("path")
      beforeSend: ->
        $throbber.removeClass("hidden")
        $responseContainer.empty()
      success: (response) ->
        $throbber.addClass("hidden")
        $responseContainer.html(response).trigger("remote:load")
