import Turbolinks from "turbolinks"

# Wrapper for `turbolinks:load` to support IE9.
# TODO: action once https://github.com/turbolinks/turbolinks/pull/166 is merged.
if Turbolinks.supported
  $(document).on "turbolinks:load", -> $(@).trigger("page:show")
else
  $(document).ready -> $(@).trigger("page:show")

$.rails.throb = (spin, $container)->
  $throbber = $('<i class="fa fa-spinner fa-spin fa-pulse fa-fw fa-3x"></i>')
  if spin
    $container.html $throbber
  else
    $container.find(".fa-spinner").remove()

$.rails.showBulkEdit = ->
  $("#bulk-edit-footer").animate({'margin-bottom': "50px"}, 300)

$.rails.hideBulkEdit = ($container) ->
  $("#bulk-edit-footer").animate({'margin-bottom': "-100px"}, 300)
  $container.find(".toggle").prop "checked", false


# Global ajax error handling ###################################################

# Handle 401 responses by redirecting to sign-in page
handleUnauthenticated = (request) ->
  # ...unless the 401 happened because the user has just failed to sign in
  # (e.g. wrong email/password)
  return if request.url == Routes.user_session_path() && request.type == "POST"

  alertify.alert "Your session has expired. Please sign-in again.", ->
    window.location.replace Routes.new_user_session_path()

# Handle 403 responses by displaying a modal dialog
handleAccessDenied = ->
  alertify.alert "Your account doesn't have the permission level required to access this resource."

# Handle 409 responses by redirecting to 409.html
handleStaleObject = ->
  if Turbolinks.supported
    Turbolinks.visit Routes.stale_path()
  else
    window.location.replace Routes.stale_path()

$(document).ajaxError (event, response, request, msg) ->
  handleUnauthenticated request if response.status == 401
  handleAccessDenied() if response.status == 403
  handleStaleObject() if response.status == 409

$(document).on "page:show remote:load", ->
  $(".link-ajax-disable").on "click", ->
    $(".link-ajax-disable").addClass("disabled") # disable all
